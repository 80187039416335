import React, { useEffect, useState } from 'react'
import { JokesData } from '../../util/jokesData.js'

export default function JokesComp() {
  const [userLocation, setUserLocation] = useState('NG')
  const [jokeArr, setJokeArr] = useState([])

  useEffect(() => {
    getUserLocation()
    setJoke()
  }, [])

  const getUserLocation = () => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    }

    // fetch('https://api.ipify.org?format=json', requestOptions)
    fetch('http://ip-api.com/json/?fields=61439', requestOptions)
      .then(response => response.json())
      .then(result => {
        setUserLocation(result?.countryCode)
      })
      .catch(error => console.log('error', error))
  }

  const setJoke = () => {
    JokesData?.map(val => {
      if (userLocation === val?.country) {
        setJokeArr(val.jokes)
      } else {
        setJokeArr(JokesData[0]?.jokes)
      }
    })
  }

  const jokeVal = jokeArr[Math.floor(Math.random() * jokeArr?.length)]

  return (
    <div className='text-center mb-5'>
      <p>{jokeVal}</p>
    </div>
  )
}
