/* eslint-disable camelcase */
import React, { useState } from 'react'
import axiosInstance from '../../../../util/axios'
import { Spinner } from 'react-bootstrap'
import { BackButton } from '../../../back_btn'
import { useSelector } from 'react-redux'
import LightenDarkenColor from '../../../../util/changecolor'
import Alert from 'react-bootstrap/Alert'

export const BankAccountVerificationComponent = (props) => {
  const [number, setNumber] = useState('')
  const [numberError, setNumberError] = useState('')
  const [bank, setBank] = useState(null)
  const [bankError, setBankError] = useState('')
  const [loading, setLoading] = useState(false)
  const [, setAccountData] = useState(null)
  const [error, setError] = useState('')
  const checkoutState = useSelector((state) => state.checkout)

  const darkColor = LightenDarkenColor(checkoutState.merchant_config?.configuration?.theme_color, -30)
  const darkestColor = LightenDarkenColor(checkoutState.merchant_config?.configuration?.theme_color, -60)

  const verifyAccount = () => {
    const data = {
      number,
      bank_code: bank,
      token: checkoutState.merchant_config?.token
    }
    setLoading(true)
    // Make a request to get account information
    axiosInstance
      .post('biometrics/merchant/data/verification/library/ng/bank_account', data)
      .then((response) => {
        // handle success
        if (response.data.status) {
          setAccountData(response.data)
          setError('')
          props.onSuccess(response.data, 'BANK-ACCOUNT')
        } else {
          setError(response.data.message)
        }
        setLoading(false)
      })
      .catch(function (error) {
        // handle error
        if ('detail' in error.response.data) {
          if ('number' in error.response.data.detail) {
            setError(error.response.data.detail.number[0])
          } else {
            setError(error.response.data.message)
          }
        }
        setLoading(false)
      })
      .then(function () {
        setLoading(false)
        // always executed
      })
  }

  const validateAccount = () => {
    if (!bank) {
      setBankError('Please select bank name')
    } else if (!number) {
      setNumberError('Account number cannot be empty')
    } else {
      verifyAccount()
    }
  }
  return (
    <div className='checkout'>
      <div className='checkout-container'>
        <div className='header mb-2'>
          <div className=' col-10 col-md-9 d-flex justify-content-between align-items-center '>
            <BackButton onClick={props.onBackBtnClick} />
            <h3 className='heading-3'>Bank Account Verification</h3>
          </div>
        </div>
        <form action='' onSubmit={(e) => e.preventDefault()}>
          <div className='mt-5 '>
            <label>Select Bank</label>
            <select
              name='bank'
              className='form-control'
              value={bank}
              onChange={(value) => setBank(value.target.value)}
            >
              <option value=''>Select bank</option>
              {props.banks.map((value, index) => (
                <option key={index} value={value.code}>
                  {value.name}
                </option>
              ))}
            </select>
            {bankError ? <p className='error-text p-0 m-0'> {bankError} </p> : null}
            {/* {numberError ? <p className='error-text p-0 m-0'> {numberError} </p> : null} */}
          </div>
          <div className='mt-3 mb-4'>
            <label>Bank Account Number</label>
            <input
              type='number'
              className='form-control'
              value={number}
              onChange={(value) => setNumber(value.target.value)}
              name='account_number'
              placeholder='14567289268'
            />
            {numberError ? <p className='error-text p-0 m-0'> {numberError} </p> : null}
          </div>
        </form>
        <div>
          {error && <Alert className='error-alert' key='error' variant='warning' style={{ color: '#091E42', backgroundColor: '#FFF0B3', fontWeight: 500 }}><i className='fa fa-eye' />{error} </Alert>}
        </div>
        <div className=' widget-footer'>
          <button
            style={{ background: `linear-gradient(${darkColor},${darkestColor})` }}
            className='btn btn-purple w-100 py-3 mb-5 mt-3'
            type='button'
            disabled={loading ? true : !!props.disable}
            // onClick={verifyAccount}
            onClick={validateAccount}
            themeColor={checkoutState.merchant_config?.configuration?.theme_color}
          >
            {loading ? (
              <div>
                <Spinner
                  as='span'
                  animation='grow'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                />
                Please wait
              </div>
            ) : (
              ' Proceed'
            )}
          </button>
        </div>
        {/* <div>
          <p className='error-text'>{error}</p>
        </div>
        <div className='input'>
          <label>Bank account number</label>
          <div className='input-field'>
            <input
              type='number'
              onChange={(value) => setNumber(value.target.value)}
              name='account_number'
            />
          </div>
        </div>
        <div className='input'>
          <label>Bank</label>
          <div className='input-field'>
            <select
              name='bank'
              className='form-control'
              onChange={(value) => setBank(value.target.value)}
            >
              <option>Select bank</option>
              {props.banks.map((value, index) => (
                <option key={index} value={value.code}>
                  {value.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <button
          style={{ background: `linear-gradient(${darkColor},${darkestColor})` }}
          disabled={loading ? true : !!props.disable}
          className='checkout-btn'
          onClick={verifyAccount}
          type='button'
          themeColor={checkoutState.merchant_config?.configuration?.theme_color}
        >
          {loading ? (
            <div>
              <Spinner
                as='span'
                animation='grow'
                size='sm'
                role='status'
                aria-hidden='true'
              />
              Please wait
            </div>
          ) : (
            ' Proceed'
          )}
        </button> */}
      </div>
    </div>
  )
}
