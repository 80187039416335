/* eslint-disable camelcase */
/* eslint-disable react/jsx-closing-tag-location */

import React, { useState } from 'react'
import ReactFlagsSelect from 'react-flags-select'
import { useSelector } from 'react-redux'
import { CountryVerificationComponent } from './../verification'
import { BackButton } from '../back_btn'
import SweetAlert from 'react-bootstrap-sweetalert'

// NIGERIA
// import individual endpoints
import { BankAccountVerificationComponent } from './../verification/Nigeria/bank/index'
import { PhoneVerificationComponent } from './../verification/Nigeria/phone/index'
import { NINVerificationComponent } from './../verification/Nigeria/nin/index'
import { BVNVerificationComponent } from './../verification/Nigeria/bvn/index'
import { InternationalPassportVerificationComponent } from './../verification/Nigeria/i_passport/index'
import { VotersCardVerificationComponent } from './../verification/Nigeria/voters_card/index'
import { DriversLicenseVerificationComponent } from './../verification/Nigeria/drivers_license/index'

// import business endpoints
import { CACNGVerificationComponent } from './../verification/Nigeria/business/cac/index'

export const CountryComponent = (props) => {
  const [country, setCountry] = useState('')
  const [activeEndpoint, setActiveEndpoint] = useState('')
  const [tempActiveEndpoint, setTempActiveEndpoint] = useState('')
  const [showAlert, setShowAlert] = useState(false)
  const [method, setMethod] = useState(null)
  const [data, setData] = useState({})

  const countries = useSelector((state) => state.checkout.merchant_config?.configuration?.countries)
  const mainCountries = []
  countries?.map((value) => mainCountries.push(value.code))

  const checkoutState = useSelector((state) => state.checkout)

  const updateVerificationChannel = () => {
    setActiveEndpoint(tempActiveEndpoint)
  }

  const goBack = () => {
    setActiveEndpoint('')
    setTempActiveEndpoint('')
  }

  const onSuccess = (data, method) => {
    setShowAlert(true)
    setMethod(method)
    setData(data)
  }

  const dipatchSuccess = () => {
    // dispatch success event for parent element
    var event_response = {
      eventInstance: 'identity_verification_event',
      data: {
        status: 'success',
        message: 'User Verified successfully',
        data: data,
        channel: method
      }
    }
    window.parent.postMessage(event_response, '*')
  }

  function LightenDarkenColor(col, amt) {
    var usePound = false

    if (col === undefined) return null

    if (col[0] === '#') {
      col = col.slice(1)
      usePound = true
    }

    var num = parseInt(col, 16)

    var r = (num >> 16) + amt

    if (r > 255) r = 255
    else if (r < 0) r = 0

    var b = ((num >> 8) & 0x00FF) + amt

    if (b > 255) b = 255
    else if (b < 0) b = 0

    var g = (num & 0x0000FF) + amt

    if (g > 255) g = 255
    else if (g < 0) g = 0

    return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16)
  }
  const darkColor = LightenDarkenColor(checkoutState.merchant_config?.configuration?.theme_color, -30)
  const darkestColor = LightenDarkenColor(checkoutState.merchant_config?.configuration?.theme_color, -60)

  return (

    (activeEndpoint === '')

      ? <div>

        <div className='header'>
          <div className=' col-10 col-md-9 d-flex justify-content-between align-items-center'>
            <BackButton onClick={props.backAction} />
            <h5 className='mt-1'>Smart Verification</h5>
          </div>
        </div>

        <div className='mt-1'>
          {/* <h5>Smart Verification</h5> */}
          {/* <p>Be done with your verification in few steps</p> */}
          {/* <h5>{checkoutState.merchant_config?.configuration?.display_name}</h5>
            <p>{checkoutState.merchant_config?.configuration?.subtitle}</p> */}
        </div>
        <form action='' className='mt-2'>

          <label>Select Country</label>
          <div className='form-control'>
            <ReactFlagsSelect
              selected={country}
              disabled={checkoutState.error || checkoutState.loading}
              searchPlaceholder='Search countries'
              placeholder='Select a Country'
              countries={mainCountries}
              onSelect={(country) => {
                setCountry(country)
              }}
            />
          </div>

          <div className='mt-3'>
            {country && <CountryVerificationComponent country={country} token={props.token} setCountry={setCountry} setActiveEndpoint={setTempActiveEndpoint} />}
          </div>
        </form>

        <div className=' widget-footer'>
          <button
            disabled={checkoutState.error || checkoutState.loading}
            className='btn btn-purple w-100 py-3 mb-5'
            style={{ marginTop: 25, background: `linear-gradient(${darkColor},${darkestColor})` }}
            onClick={updateVerificationChannel} type='button'
          >
            {checkoutState.loading ? 'Please wait' : 'Proceed'}
          </button>
        </div>
      </div>
      : <div>
        <SweetAlert
          show={showAlert}
          custom
          showCloseButton
          confirmBtnText='Return to Merchant'
          cancelBtnText='Dismiss'
          confirmBtnBsStyle='light'
          customIcon='https://raw.githubusercontent.com/djorg83/react-bootstrap-sweetalert/master/demo/assets/thumbs-up.jpg'
          title='Verification Successful'
          onConfirm={dipatchSuccess}
          onCancel={() => {}}
        >
          Your details has been received
        </SweetAlert>
        {
          activeEndpoint === 'LIB-NIG-BANK_ACCOUNT' &&
            <BankAccountVerificationComponent
              banks={checkoutState.banks}
              token={props.token}
              disable={props.error_message}
              onSuccess={onSuccess}
              onBackBtnClick={goBack}
              dipatchSuccess={dipatchSuccess}
            />
        }
        {
          activeEndpoint === 'LIB-NIG-PHONE-NUMBER' &&
            <PhoneVerificationComponent
              token={props.token}
              disable={props.error_message}
              onSuccess={onSuccess}
              onBackBtnClick={goBack}
              dipatchSuccess={dipatchSuccess}
            />
        }

        {
          activeEndpoint === 'LIB-NIG-NIN' &&
            <NINVerificationComponent
              token={props.token}
              disable={props.error_message}
              onSuccess={onSuccess}
              onBackBtnClick={goBack}
              dipatchSuccess={dipatchSuccess}
            />
        }

        {
          activeEndpoint === 'LIB-NIG-BVN' &&
            <BVNVerificationComponent
              token={props.token}
              disable={props.error_message}
              onSuccess={onSuccess}
              onBackBtnClick={goBack}
              dipatchSuccess={dipatchSuccess}
            />
        }

        {
          activeEndpoint === 'LIB-NIG-VOTERS-CARD' &&
            <VotersCardVerificationComponent
              token={props.token}
              disable={props.error_message}
              onSuccess={onSuccess}
              onBackBtnClick={goBack}
              dipatchSuccess={dipatchSuccess}
            />
        }

        {
          activeEndpoint === 'LIB-NIG-FRSC' &&
            <DriversLicenseVerificationComponent
              token={props.token}
              disable={props.error_message}
              onSuccess={onSuccess}
              onBackBtnClick={goBack}
              dipatchSuccess={dipatchSuccess}
            />
        }

        {
          activeEndpoint === 'LIB-NIG-NATIONAL-PASSPORT' &&
            <InternationalPassportVerificationComponent
              token={props.token}
              disable={props.error_message}
              onSuccess={onSuccess}
              onBackBtnClick={goBack}
              dipatchSuccess={dipatchSuccess}
            />
        }

        {
          activeEndpoint === 'LIB-NIG-CAC' &&
            <CACNGVerificationComponent
              token={props.token}
              disable={props.error_message}
              onSuccess={onSuccess}
              onBackBtnClick={goBack}
              dipatchSuccess={dipatchSuccess}
            />

        }

      </div>

  )
}
