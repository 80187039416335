import React from 'react'
// import LightenDarkenColor from '../../util/changecolor'
// import { useSelector } from 'react-redux'
import backImg from '../../assets/back-img.png'

export const BackButton = (props) => {
  // const checkoutState = useSelector((state) => state.checkout)
  // const darkestColor = LightenDarkenColor(checkoutState.merchant_config?.configuration?.theme_color, -60)
  return (
    <>
      <img src={backImg} alt='' style={{ height: '15px', cursor: 'pointer' }} onClick={props.onClick} />
      {/* <button className='btn back-btn p-0' style={{ font: '14px S-bold', padding: 5 }} onClick={props.onClick}>
        <div style={{ padding: 3, paddingLeft: 5, paddingRight: 10 }}><i className='feather icon-arrow-left mr-2' style={{ color: darkestColor, fontWeight: 'bold' }} />
          Back
        </div>
      </button> */}
    </>
  )
}
