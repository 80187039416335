/* eslint-disable camelcase */
import React, { useState } from 'react'
import axiosInstance from '../../../../util/axios'
import { NINNumberComponent } from './number'
import { NINImageComponent } from './image'
import { BackButton } from '../../../back_btn'
import { CameraComponent } from '../../../camera'
import { useSelector } from 'react-redux'
import { FailedResponseComp, SuccessResponseComp } from '../../../response'
import LightenDarkenColor from '../../../../util/changecolor'

export const NINVerificationComponent = (props) => {
  const [mode, setMode] = useState('number')
  const [mainPage, setMainPage] = useState(1)
  const [verificationMode, setVerificationMode] = useState('')
  // const [mainStep, setMainStep] = useState(1)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [, setNINData] = useState(null)
  const [ninReqData, setNINReqData] = useState(null)
  // const [showSelfie, setShowSelfie] = useState(false)
  const [resp, setResp] = useState('')

  const checkoutState = useSelector((state) => state.checkout)
  const darkColor = LightenDarkenColor(checkoutState.merchant_config?.configuration?.theme_color, -30)
  const darkestColor = LightenDarkenColor(checkoutState.merchant_config?.configuration?.theme_color, -60)

  const verifyNIN = (image) => {
    setLoading(true)
    var data = ninReqData
    data.token = checkoutState.merchant_config?.token
    data.mode = mode
    data.image = image
    // Make a request to get account information
    axiosInstance
      .post('biometrics/merchant/data/verification/library/ng/nin', data)
      .then((response) => {
        // handle success
        if (response.data.status) {
          setNINData(response.data)
          setError('')
          setResp('2')
          props.onSuccess(response.data, 'NIN')
        } else {
          setResp('3')
          setError(response.data.message)
        }
        setLoading(false)
      })
      .catch(function (error) {
        // handle error
        if ('detail' in error.response.data) {
          if ('number' in error.response.data.detail) {
            setResp('3')
            setError(error.response.data.detail.number[0])
          } else {
            setResp('3')
            setError(error.response.data.detail)
          }
        }
        setLoading(false)
      })
      .then(function () {
        setLoading(false)
        // always executed
      })
  }

  const onDismiss = () => {
    setVerificationMode('')
    setError('')
  }
  const handleMainStep = (child) => {
    setMainPage(child)
  }

  const updateSelfie = (value, data) => {
    value ? setResp('1') : setResp('')
    // setShowSelfie(value)
    setNINReqData(data)
  }

  const dismissCamera = () => {
    setResp('')
    // setShowSelfie(false)
    setError('')
  }

  const changePage = (val) => {
    setResp(val)
    setError('')
  }

  if (verificationMode === 'number') {
    return <NINNumberComponent updateSelfie={updateSelfie} verifyNIN={verifyNIN} handleMainStep={handleMainStep} onDismiss={onDismiss} loading={loading} error={error} />
  } else if (verificationMode === 'image') {
    return <NINImageComponent updateSelfie={updateSelfie} verifyNIN={verifyNIN} handleMainStep={handleMainStep} onBackBtnClick={props.onBackBtnClick} onDismiss={onDismiss} loading={loading} error={error} />
  }

  // if (showSelfie) {
  //   return <CameraComponent onCapture={verifyNIN} dismissCamera={dismissCamera} loading={loading} error={error} facingMode='user' header='Capture your selfie' />
  // }
  if (resp === '1') {
    return <CameraComponent onCapture={verifyNIN} dismissCamera={dismissCamera} loading={loading} error={error} facingMode='user' header='Capture your selfie' />
  } else if (resp === '2') {
    return <SuccessResponseComp changePage={changePage} dipatchSuccess={props.dipatchSuccess} darkColor={darkColor} darkestColor={darkestColor} />
  } else if (resp === '3') {
    return <FailedResponseComp changePage={changePage} darkColor={darkColor} darkestColor={darkestColor} error={error} />
  }

  return (
    <div className='checkout'>
      <div className='checkout-container'>
        {mainPage === 1 &&
          <>
            <div className='header'>
              <div className=' col-10 col-md-9 d-flex justify-content-between align-items-center '>
                <BackButton onClick={props.onBackBtnClick} />
                <h3 className='heading-3'>NIN Verification</h3>
              </div>
            </div>

            <div className='display-mode mt-5'>
              <div>
                <button
                  className={mode === 'number' ? 'btn btn-grey active' : 'btn btn-grey'}
                  onClick={() => setMode('number')}
                >
                  Use NIN digits
                </button>
                <button
                  className={mode === 'image' ? 'btn btn-grey active' : 'btn btn-grey'}
                  onClick={() => setMode('image')}
                >
                  Use slip Image (NIMC)
                </button>
              </div>
            </div>
          </>}
        <div className='display-body'>
          {
            mode === 'number' &&
              <>
                <NINNumberComponent updateSelfie={updateSelfie} verifyNIN={verifyNIN} handleMainStep={handleMainStep} onDismiss={onDismiss} loading={loading} error={error} />
              </>
          }
          {
            mode === 'image' &&
              <>
                <NINImageComponent updateSelfie={updateSelfie} verifyNIN={verifyNIN} handleMainStep={handleMainStep} onBackBtnClick={props.onBackBtnClick} onDismiss={onDismiss} loading={loading} error={error} />
              </>
          }
        </div>
      </div>
    </div>
  )
}
