export const JokesData = [
  {
    country: 'NG',
    jokes: [
      'Smile like Portable. - Zazuu . . .Zeee',
      'We need your beautiful smile, oya prove say you fine',
      'You no wan show us your 32 abi, just dey play',
      'Yoga Yoga….ha ha, just smile',
      'Soso….smile away',
      'Your face show, your teeth white'
    ]
  },
  {
    country: 'GH',
    jokes: [
      'Akwaaba, now give us a smile',
      'Chale give us a big smile',
      'Nothing looks good as much as Ghanaian Jollof and your beautiful smile',
      'Chale no palava, flash your teeth small',
      'Eti sen, You’re so fine ei'

    ]
  },
  {
    country: 'KY',
    jokes: [
      'Hakuna Matata’ my friend, now put on a big smile',
      'Murife don’t run, just smile',
      'Karibu, give us a big smile',
      'Pole pole, now smile',
      'Your smile is beautiful, Zoea hiyo sauti',
      'Habari, now is time to smile'
    ]
  },
  {
    country: 'UG',
    jokes: [
      'Oli otyam, put on a big smile',
      'Smile Mukwano',
      'Nsanyuse okulaba, give us a big smile'
    ]
  },
  {
    country: 'SA',
    jokes: [
      'Howzit?, put on a big smile',
      'You have a lekker smile, now show it ',
      'Eish! You have a beautiful smile, let’s see it'
    ]
  },
  {
    country: 'SL',
    jokes: [
      'Give me a smile Padi',
      'Kushɛh, we need you to smileEish! You have beautiful smile '
    ]
  },
  {
    country: 'EU',
    jokes: [
      'Smile mate! This process is almost over',
      'The End Is Near: Get Your Smile On Now'
    ]
  },
  {
    country: 'US',
    jokes: [
      'Almost finished Buddy! Give us a smile and you will make it through',
      'Almost done Pal! Just keep smiling and you will be done in no time.'
    ]
  }
]
