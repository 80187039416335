/* eslint-disable camelcase */
import React, { useState, useRef } from 'react'
import axiosInstance from '../../../../util/axios'
import { CameraComponent } from '../../../camera'
import { BackButton } from '../../../back_btn'
import { useSelector } from 'react-redux'
import LightenDarkenColor from '../../../../util/changecolor'
import Alert from 'react-bootstrap/Alert'
import { FailedResponseComp, SuccessResponseComp } from '../../../response'

export const BVNVerificationComponent = (props) => {
  const [number, setNumber] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  // const [showSelfie, setShowSelfie] = useState(false)
  const [resp, setResp] = useState('')
  const number_ref = useRef(number)
  number_ref.current = number

  const checkoutState = useSelector((state) => state.checkout)
  const darkColor = LightenDarkenColor(checkoutState.merchant_config?.configuration?.theme_color, -30)
  const darkestColor = LightenDarkenColor(checkoutState.merchant_config?.configuration?.theme_color, -60)

  const verifybvn = (image) => {
    const data = {
      number,
      token: checkoutState.merchant_config?.token,
      image
    }
    setLoading(true)
    // Make a request to get account information
    axiosInstance
      .post('biometrics/merchant/data/verification/library/ng/bvn', data)
      .then((response) => {
        // handle success
        if (response.data.status) {
          setError('')
          setResp('2')
          props.onSuccess(response.data, 'BVN')
        } else {
          setResp('3')
          setError(response.data.message)
        }
        setLoading(false)
      })
      .catch(function (error) {
        // handle error
        if ('detail' in error.response.data) {
          if ('number' in error.response.data.detail) {
            setResp('3')
            setError(error.response.data.detail.number[0])
          } else {
            setResp('3')
            setError(error.response.data.detail)
          }
        }
        setLoading(false)
      })
      .then(function () {
        setLoading(false)
        // always executed
      })
  }

  const dismissCamera = () => {
    setResp('')
    // setShowSelfie(false)
    setError('')
  }

  const onFormSubmit = () => {
    if (!number) {
      setError('Bank Verification Number cannot be Empty')
    } else if (/^[0-9]{11}$/.test(number) === false) {
      setError('Invalid Bank Verification Number')
    } else {
      setError('')
      setResp('1')
      // setShowSelfie(true)
    }
  }
  const changePage = (val) => {
    setResp(val)
    setError('')
  }

  if (resp === '1') {
    return <CameraComponent onCapture={verifybvn} dismissCamera={dismissCamera} loading={loading} error={error} facingMode='user' header='Capture your selfie' />
  // if (showSelfie && resp === '1') {
    // return <CameraComponent onCapture={verifybvn} dismissCamera={dismissCamera} loading={loading} error={error} facingMode='user' header='Capture your selfie' />
  } else if (resp === '2') {
    return <SuccessResponseComp changePage={changePage} dipatchSuccess={props.dipatchSuccess} darkColor={darkColor} darkestColor={darkestColor} />
  } else if (resp === '3') {
    return <FailedResponseComp changePage={changePage} darkColor={darkColor} darkestColor={darkestColor} error={error} />
  }

  return (
    <div className='checkout'>
      <div className='checkout-container'>
        <>
          <div className='header'>
            <div className=' col-10 col-md-9 d-flex justify-content-between '>
              <BackButton onClick={props.onBackBtnClick} />
              <h3 className='heading-3'>Bank Verification Number</h3>
            </div>
          </div>
          <form action='' onSubmit={(e) => e.preventDefault()}>
            <div className='mb-5 mt-2'>
              <label>Bank Verification Number</label>
              <input
                type='number'
                name='bvn_number'
                value={number}
                className='form-control'
                onChange={(value) => setNumber(value.target.value)}
                placeholder='14567289268'
              />
            </div>
          </form>
          <div>

            {error
              ? <Alert className='error-alert' key='error' variant='warning' style={{ color: '#091E42', backgroundColor: '#FFF0B3', fontWeight: 500 }}><i className='fa fa-eye' />{error} </Alert>
              : null}

          </div>
          <div className=' widget-footer'>
            <button
              style={{ background: `linear-gradient(${darkColor},${darkestColor})` }}
              className='btn btn-purple w-100 py-3 mb-5'
              onClick={onFormSubmit}
              type='button'
            >
              Proceed
            </button>
          </div>
        </>
      </div>
    </div>
  )
}
