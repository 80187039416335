import React from 'react'

export default function ProgressSteps(props) {
  return (
    <div>
      {props.step === 0 &&
        <div
          className='one-fourth-circle'
          style={{ border: `solid 20px ${props.darkestColor}`, borderColor: `${props.darkestColor} transparent transparent transparent` }}
        />}
      {props.step === 1 &&
        <>
          <div
            className='one-fourth-circle'
            style={{ border: `solid 20px ${props.darkestColor}`, borderColor: `${props.darkestColor} transparent transparent transparent` }}
          />
          <div
            className='two-fourth-circle'
            style={{
              border: `solid 20px ${props.darkestColor}`,
              borderColor: ` transparent${props.darkestColor} transparent transparent `
            }}
          />
          <div
            className='three-fourth-circle'
            style={{
              border: `solid 20px ${props.darkestColor}`,
              borderColor: ` transparent transparent ${props.darkestColor} transparent `
            }}
          />
        </>}
      {props.step === 2 &&
        <>
          <div
            className='one-fourth-circle'
            style={{ border: `solid 20px ${props.darkestColor}`, borderColor: `${props.darkestColor} transparent transparent transparent` }}
          />
          <div
            className='two-fourth-circle'
            style={{
              border: `solid 20px ${props.darkestColor}`,
              borderColor: ` transparent${props.darkestColor} transparent transparent `
            }}
          />
          <div
            className='three-fourth-circle'
            style={{
              border: `solid 20px ${props.darkestColor}`,
              borderColor: ` transparent transparent ${props.darkestColor} transparent `
            }}
          />
          <div
            className='complete-circle'
            style={{
              border: `solid 20px ${props.darkestColor}`,
              borderColor: ` transparent transparent transparent ${props.darkestColor} `
            }}
          />
        </>}
    </div>
  )
}
