import React from 'react'
import successImg from '../../assets/success-img.png'
import failedImg from '../../assets/failed-img.png'
import backImg from '../../assets/back-img.png'

export function SuccessResponseComp(props) {
  return (
    <div className='text-center'>
      <div className='text-center mt-md-3 col-10 col-md-9 d-flex justify-content-between align-items-center'>
        <img src={backImg} alt='' style={{ height: '15px', cursor: 'pointer' }} onClick={() => props.changePage('1')} />
        <h5>Smart Verification</h5>
      </div>

      <div className='mt-5'>
        <h4>Verification Successful</h4>
        <div className='col-md-8 mx-auto my-5'>
          <img src={successImg} alt='' className='w-100' />
        </div>
        <p>Your details have been received</p>

        <button
          style={{ background: `linear-gradient(${props?.darkColor},${props?.darkestColor})` }}
          className='btn btn-purple w-100 py-3 mt-5'
          type='button'
          onClick={props.dipatchSuccess}
        >
          Close
        </button>
        {/* <button className='btn btn-blue w-100 py-3 mt-5'> </button> */}
      </div>

    </div>
  )
}

export function FailedResponseComp(props) {
  return (
    <div className='text-center'>
      <div className='text-center mt-md-3 col-10 col-md-9 d-flex justify-content-between align-items-center'>
        <img src={backImg} alt='' style={{ height: '15px', cursor: 'pointer' }} onClick={() => props.changePage('1')} />
        <h5>Smart Verification</h5>
      </div>

      <div className='mt-5'>
        <h4>Verification Failed</h4>
        <div className='col-md-8 mx-auto my-5'>
          <img src={failedImg} alt='' className='w-100' />
        </div>
        <p style={{ fontSize: '15px' }}>{props.error}</p>

        <button
          style={{ background: `linear-gradient(${props?.darkColor},${props?.darkestColor})` }}
          className='btn btn-purple w-100 py-3 my-5 '
          type='button'
          onClick={() => props.changePage('')}
        >
          Retry
        </button>
      </div>
    </div>
  )
}

export function FailedResponseWithReasonComp() {
  return (
    <div>
      <div className='text-center mt-3 col-9 d-flex justify-content-between'>
        <i className='ri-arrow-left-line' />
        <h5>Smart Verification</h5>
      </div>

      <div className='mt-5 text-center'>
        <h4>Verification Failed</h4>
        <div className='col-md-8 mx-auto my-5'>
          <img src={failedImg} alt='' className='w-100' />
        </div>
        <p>
          If mobile phone camera pop up
          fails, <a href='http://' target='_blank' rel='noopener noreferrer'>Click here</a> to
          receive a link into your email to use other device.
        </p>
        <p className='mt-3'>
          If failed due to other technical
          error; <a href='http://' target='_blank' rel='noopener noreferrer'>Click here</a> to
          read FAQ guide to solve any technical error yourself
        </p>
      </div>
    </div>
  )
}
